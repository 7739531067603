body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-color: white;
    background-color: #1a1d23;
}

.login {
    background-color: #282c34;
}

.universe {
    background-color: #1a1d23;
}

.universe > * {
    outline: none !important;
    border: none !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.star {
    position: absolute;
    z-index: 0;
    width: 2px;
    height: 2px;
    background: white;
    border-radius: 50%;
    animation: twinkling var(--animation-duration) linear infinite;
}

@keyframes twinkling {
    0%,
    100% {
        opacity: 0.6;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(1.5);
    }
}
